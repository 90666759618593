.heading {
    /*FLEX BOX SETTINGS */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    font-family: 'raleway';
    font-size: large;
}

.cateringlogo {
    width: 30vw;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    
  }